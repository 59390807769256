@use '@angular/material' as mat;

@include mat.core();

// 
html,
body {
  height: 100%;
  margin: 0;
}


@media (min-width: 0px) {
  html {
    font-size: 14px !important;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 16px !important;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 16px !important;
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 18px !important;
  }
}

@media (min-width: 1536px) {
  html {
    font-size: 18px !important;
  }
}



// 
.ks-modal-gallery-backdrop {
  background: #000 !important;
  ;
  opacity: 0.85 !important;
  ;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}


// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
@import 'tailwindcss/utilities';


a,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}